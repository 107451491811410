import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {AsyncStatus} from "constants/asyncStatus";
import {
    setChatMainPanelThread
} from "features/ui/mainPanels/chatMainPanel/chatMainPanelSlice";
import {
    selectOpenAIThreadsDataByThreadId,
    selectOpenAIThreadsStatus
} from "features/entities/openAIThreads/openAIThreadsSelectors";
import {
    selectOpenAIThreadMessagesDataByThreadId,
    selectOpenAIThreadMessagesStatus
} from "features/entities/openAIThreadMessages/openAIThreadMessagesSelectors";
import {
    selectOpenAIAssistantsData,
    selectOpenAIAssistantsStatus
} from "features/entities/openAIAssistants/openAIAssistantsSelectors";


const useSyncOpenAIThreadToUI = ({openAIThreadId}) => {

    const dispatch = useDispatch();

    // session 
    const openAIThread = useSelector(state => selectOpenAIThreadsDataByThreadId(state, openAIThreadId));
    const openAIThreadsStatus = useSelector(state => selectOpenAIThreadsStatus(state));
    const openAIThreadMessages = useSelector(state => selectOpenAIThreadMessagesDataByThreadId(state, openAIThreadId));
    const openAIThreadMessagesStatus = useSelector(state => selectOpenAIThreadMessagesStatus(state));

    const openAIAssistantsData = useSelector(state => selectOpenAIAssistantsData(state));
    const openAIAssistantsStatus = useSelector(state => selectOpenAIAssistantsStatus(state));

    useEffect(() => {
        if (openAIThreadsStatus !== AsyncStatus.SUCCESS || ! openAIThread) {return;}
        if (openAIAssistantsStatus !== AsyncStatus.SUCCESS || ! Array.isArray(openAIAssistantsData)) {return;}
        if (openAIThreadMessagesStatus !== AsyncStatus.SUCCESS || ! Array.isArray(openAIThreadMessages)) {return;}
        const openAIAssistant = openAIAssistantsData.find(assistant => assistant.id === openAIThread.openai_assistant_id);
        if (! openAIAssistant) {return;}
        const name = openAIThread.name;
        const prompts = openAIAssistant.sample_prompts;
        // only if the message have instance will we create a ui message
        const messages = []
        openAIThreadMessages.forEach(message => {
            if (message.instance) {
                messages.push({
                    id: message.id,
                    role: message.instance.role,
                    content: message.instance.content.at(0).text.value,
                    annotations: message.instance.content.at(0).text.annotations
                })
            }
        })
        dispatch(setChatMainPanelThread({
            openAIThreadId: openAIThreadId,
            openAIThreadName: name,
            openAIThreadMessages: messages,
            openAIAssistantSamplePrompts: prompts
        }))
    }, [
        dispatch,
        openAIThreadId,
        openAIThreadMessages,
        openAIThreadMessagesStatus,
        openAIAssistantsData,
        openAIAssistantsStatus,
        openAIThread,
        openAIThreadsStatus,
    ]);

}

export default useSyncOpenAIThreadToUI;