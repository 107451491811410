import {createAsyncThunk} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import ToastifyConfig from "configs/toastifyConfig";
import authService from "services/authService";
import {clearSession} from "./sessionSlice";
import {getUser} from "features/entities/user/userThunks";
import {clearUser} from "features/entities/user/userSlice";
import {getAllAccessibleOpenAIAssistants} from "features/entities/openAIAssistants/openAIAssistantsThunks";
import {getAllOpenAIChatModels} from "features/entities/openAIChatModels/openAIChatModelsThunks";
import {clearOpenAIChatModels} from "features/entities/openAIChatModels/openAIChatModelsSlice";
import {clearOpenAIAssistants} from "features/entities/openAIAssistants/openAIAssistantsSlice";
import {getUserFavoriteOpenAIAssistants} from "features/entities/userFavoriteOpenAIAssistants/userFavoriteOpenAIAssistantsThunks";
import {clearUserFavoriteOpenAIAssistants} from "features/entities/userFavoriteOpenAIAssistants/userFavoriteOpenAIAssistantsSlice";
import {getOpenAIThreads} from "features/entities/openAIThreads/openAIThreadsThunks";
import {clearOpenAIThreads} from "features/entities/openAIThreads/openAIThreadsSlice";
import {clearOpenAIThreadMessages} from "features/entities/openAIThreadMessages/openAIThreadMessagesSlice";
import {getOpenAIVectorStores} from "features/entities/openAIVectorStores/openAIVectorStoresThunks";
import {clearOpenAIVectorStores} from "features/entities/openAIVectorStores/openAIVectorStoresSlice";
import {getUserFollowers} from "../entities/userFollowers/userFollowersThunks";
import {clearUserFollowers} from "../entities/userFollowers/userFollowersSlice";


// Session是很关键的起点，很多资源都是由Session来获取和分发的
const initSession = createAsyncThunk(
    'session/initSession',
    async ({ token }, { dispatch, getState, rejectWithValue }) => {
        try {
            localStorage.setItem('token', token);

            dispatch(getUser({ token }))

            dispatch(getUserFavoriteOpenAIAssistants({ token }))
            dispatch(getAllOpenAIChatModels({ token }))
            dispatch(getAllAccessibleOpenAIAssistants({ token }))
            dispatch(getOpenAIThreads({ token }))
            dispatch(getOpenAIVectorStores({ token }))
            dispatch(getUserFollowers({ token }))

            return {
                token: token
            }
        } catch (error) {
            return rejectWithValue({
                error: 'Fail to initialize session.',
                details: error
            });
        }
    }
);

const resumeSession = createAsyncThunk(
    'session/refreshSession',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const toastId = toast.loading("Resuming last login session...");
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "No token found in local storage.", type: "error"});
                return rejectWithValue({
                    error: 'No token found in local storage.',
                    details: 'No token found in local storage.'
                });
            }
            const responseJson = await authService.refreshJWT({
                token: token
            });
            if (responseJson.hasOwnProperty("error")) {
                localStorage.removeItem('token');
                toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to refresh session.", type: "error"});
                return rejectWithValue(responseJson);
            }
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Session refreshed!", type: "success"});
            const refreshedToken = responseJson.token;
            dispatch(initSession({ token: refreshedToken }))
            return {
                token: refreshedToken
            }
        } catch (error) {
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to refresh session.", type: "error"});
            return rejectWithValue({
                error: 'Fail to refresh session.',
                details: error
            });
        }
    }
);

const terminateSession = createAsyncThunk(
    'session/terminateSession',
    async (_, { dispatch, getState, rejectWithValue }) => {
        const toastId = toast.loading("Logging out...");
        try {
            localStorage.removeItem('token');

            dispatch(clearUser());

            dispatch(clearUserFavoriteOpenAIAssistants());
            dispatch(clearOpenAIChatModels());
            dispatch(clearOpenAIAssistants());
            dispatch(clearOpenAIThreads());
            dispatch(clearOpenAIThreadMessages());
            dispatch(clearOpenAIVectorStores());
            dispatch(clearUserFollowers());
            dispatch(clearSession());

            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Logged out!", type: "success"});

        } catch (error) {
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to logout!", type: "error"});
            return rejectWithValue({
                error: 'Fail to terminate session.',
                details: error
            });
        }
    }
);

export {
    initSession,
    resumeSession,
    terminateSession
};