import {useDispatch, useSelector} from "react-redux";

import SiteConfig from "configs/siteConfig";
import {PseudoOpenAIThreadMessageId} from "constants/pseudoOpenAIThreadMessageId";
import {OpenAIMessageRole} from "constants/openAIMessageRole";
import {OpenAIThreadRunEvent} from "constants/openAIThreadRunEvent";
import {addOpenAIThreadMessage} from "features/entities/openAIThreadMessages/openAIThreadMessagesSlice";
import {setUserQuota} from "features/entities/user/userSlice";
import {selectSessionToken} from "features/session/sessionSelectors";
import {
    createChatMainPanelThreadMessageWithId,
    updateChatMainPanelThreadMessageById
} from "features/ui/mainPanels/chatMainPanel/chatMainPanelSlice";
import {
    createOpenAIThreadMessage
} from "features/entities/openAIThreadMessages/openAIThreadMessagesThunks";


const useSubmitMessage = ({openAIThreadId}) => {

    const dispatch = useDispatch();

    const sessionToken = useSelector(selectSessionToken);

    const submitMessage = async (content) => {

        // put the user message in redux ui state (this will not affect the entity state)
        await dispatch(createChatMainPanelThreadMessageWithId({
            openAIThreadId: openAIThreadId,
            openAIThreadMessage: {
                id: PseudoOpenAIThreadMessageId.USER,
                role: OpenAIMessageRole.USER,
                content: content,
            }
        }))

        // wait for 500ms （simulate the delay of the assistant response）
        await new Promise(resolve => setTimeout(resolve, 500))

        // put the assistant message in redux ui state
        await dispatch(createChatMainPanelThreadMessageWithId({
            openAIThreadId: openAIThreadId,
            openAIThreadMessage: {
                id: PseudoOpenAIThreadMessageId.ASSISTANT,
                role: OpenAIMessageRole.ASSISTANT,
                content: "",
            }
        }))

        // flag to check if the pseudo assistant message exists
        let pseudoAssistantMessageExists = true;

        // create the message in the backend
        await dispatch(createOpenAIThreadMessage({
            token: sessionToken,
            openAIThreadId: openAIThreadId,
            role: OpenAIMessageRole.USER,
            content:content
        }));

        // text buffer for the assistant message
        let textBuffer = ''

        // timestamp for the last update
        let lastUpdateTimestamp = new Date().getTime()

        // create the event source
        let endpoint = `${SiteConfig.API_BASE_URL}/openai-thread-runs/me/${openAIThreadId}`
        endpoint = endpoint.concat(`?Authorization=Bearer ${sessionToken}`)

        // 创建一个新的EventSource实例，并附带Authorization头
        const eventSource = new EventSource(endpoint);

        eventSource.addEventListener(OpenAIThreadRunEvent.TEXT_CREATED, (event) => {
            if (! pseudoAssistantMessageExists) {
                dispatch(createChatMainPanelThreadMessageWithId({
                    openAIThreadId: openAIThreadId,
                    openAIThreadMessage: {
                        id: PseudoOpenAIThreadMessageId.ASSISTANT,
                        role: OpenAIMessageRole.ASSISTANT,
                        content: "",
                    }
                }))
            }
        });

        eventSource.addEventListener(OpenAIThreadRunEvent.TEXT_DELTA, (event) => {
            const data = JSON.parse(event.data)
            const text_delta = data.text_delta
            textBuffer += text_delta
            if (new Date().getTime() - lastUpdateTimestamp < 100) {
                return
            }
            lastUpdateTimestamp = new Date().getTime()
            dispatch(updateChatMainPanelThreadMessageById({
                openAIThreadId: openAIThreadId,
                openAIThreadMessage: {
                    id: PseudoOpenAIThreadMessageId.ASSISTANT,
                    role: OpenAIMessageRole.ASSISTANT,
                    content: textBuffer
                }
            }))
        });

        eventSource.addEventListener(OpenAIThreadRunEvent.MESSAGE_DONE, (event) => {
            const data = JSON.parse(event.data)
            const openai_thread_message = data.openai_thread_message
            textBuffer = ''
            pseudoAssistantMessageExists = false
            dispatch(addOpenAIThreadMessage({
                openAIThreadId: openAIThreadId,
                openAIThreadMessage: openai_thread_message
            }))
        });

        eventSource.addEventListener(OpenAIThreadRunEvent.USER_QUOTA_UPDATED, (event) => {
            const data = JSON.parse(event.data)
            const user_quota = data.user_quota
            dispatch(setUserQuota({
                userQuota: user_quota
            }))
        });

        eventSource.addEventListener(OpenAIThreadRunEvent.COMPLETED, (event) => {
            // console.log('EventSource completed.')
            eventSource.close();
        });

        eventSource.onerror = (err) => {
            console.error('EventSource failed:', err);
            eventSource.close();
        };

        eventSource.onopen = () => {
            // console.log('EventSource connected.');
        };

        eventSource.onclose = () => {
            // console.log('EventSource closed.');
        };
    }

    return {
        submitMessage
    }
}

export default useSubmitMessage;