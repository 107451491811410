import {createAsyncThunk} from "@reduxjs/toolkit";
import openAIAssistantService from "services/openAIAssistantService";
import {toast} from "react-toastify";
import ToastifyConfig from "configs/toastifyConfig";

const getAllAccessibleOpenAIAssistants = createAsyncThunk(
    'entities/openAIAssistants/getAllAccessibleOpenAIAssistants',
    async ( {token }, { dispatch, getState, rejectWithValue }) => {
        try {
            const [publicAssistantsResponseJson, protectedAssistantsResponseJson, userAssistantsResponseJson] = await Promise.all([
                openAIAssistantService.getPublicAssistants({ token }),
                openAIAssistantService.getProtectedAssistantsOfFollowedUsersForCurrentUser({ token }),
                openAIAssistantService.getAssistantsForCurrentUser({token: token})
            ]);
            if (publicAssistantsResponseJson.hasOwnProperty("error")) {
                return rejectWithValue(publicAssistantsResponseJson);
            }
            if (protectedAssistantsResponseJson.hasOwnProperty("error")) {
                return rejectWithValue(protectedAssistantsResponseJson);
            }
            if (userAssistantsResponseJson.hasOwnProperty("error")) {
                return rejectWithValue(userAssistantsResponseJson);
            }
            let assistant_ids = userAssistantsResponseJson.assistants.map(assistant => assistant.id);
            let assistants = userAssistantsResponseJson.assistants
            for (let assistant of protectedAssistantsResponseJson.assistants) {
                if (!assistant_ids.includes(assistant.id)) {
                    assistants.push(assistant);
                    assistant_ids.push(assistant.id);
                }
            }
            for (let assistant of publicAssistantsResponseJson.assistants) {
                if (!assistant_ids.includes(assistant.id)) {
                    assistants.push(assistant);
                    assistant_ids.push(assistant.id);
                }
            }
            return {
                assistants: assistants
            };
        } catch (error) {
            return rejectWithValue({
                error: 'Fail to get accessible openAI assistants.',
                details: error.message
            });
        }
    }
);

const createOpenAIAssistant = createAsyncThunk(
    'entities/openAIAssistants/createOpenAIAssistant',
    async ( {token, name, description, instructions, samplePrompts, tags, accessLevel, openAIChatModelId, openAIVectorStoreIds}, { dispatch, getState, rejectWithValue }) => {
        const toastId = toast.loading("Creating chatbot...");
        try {
            const ResponseJson = await openAIAssistantService.createOpenAIAssistantForCurrentUser({
                token: token,
                name: name,
                description: description,
                instructions: instructions,
                samplePrompts: samplePrompts,
                tags: tags,
                accessLevel: accessLevel,
                openAIChatModelId: openAIChatModelId,
                openAIVectorStoreIds: openAIVectorStoreIds
            })
            if (ResponseJson.hasOwnProperty("error")) {
                toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Chatbot creation failed!", type: "error"});
                return rejectWithValue(ResponseJson);
            }
            const assistant = ResponseJson.assistant;
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Chatbot created!", type: "success"});
            return {
                assistant: assistant
            }
        } catch (error) {
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Chatbot creation failed!", type: "error"});
            return rejectWithValue({
                error: 'Fail to create openAI assistant.',
                details: error.message
            });
        }
    }
);

const updateOpenAIAssistant = createAsyncThunk(
    'entities/openAIAssistants/updateOpenAIAssistant',
    async ({token, openAIAssistantId, name, description, instructions, samplePrompts, tags, accessLevel, openAIChatModelId, openAIVectorStoreIds}, { dispatch, getState, rejectWithValue }) => {
        const toastId = toast.loading("Updating chatbot...");
        try {
            const ResponseJson = await openAIAssistantService.updateOpenAIAssistantForCurrentUser({
                token: token,
                openAIAssistantId: openAIAssistantId,
                name: name,
                description: description,
                instructions: instructions,
                samplePrompts: samplePrompts,
                tags: tags,
                accessLevel: accessLevel,
                openAIChatModelId: openAIChatModelId,
                openAIVectorStoreIds: openAIVectorStoreIds
            })
            if (ResponseJson.hasOwnProperty("error")) {
                toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Chatbot update failed!", type: "error"});
                return rejectWithValue(ResponseJson);
            }
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Chatbot updated!", type: "success"});
            return {
                assistant: ResponseJson.assistant
            }
        } catch (error) {
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Chatbot update failed!", type: "error"});
            return rejectWithValue({
                error: 'Fail to update openAI assistant.',
                details: error.message
            });
        }
    }
);

const deleteOpenAIAssistant = createAsyncThunk(
    'entities/openAIAssistants/deleteOpenAIAssistant',
    async ({token, openAIAssistantId}, { dispatch, getState, rejectWithValue }) => {
        const toastId = toast.loading("Deleting chatbot...");
        try {
            const ResponseJson = await openAIAssistantService.deleteAssistantForCurrentUser({
                token: token,
                openAIAssistantId: openAIAssistantId
            })
            if (ResponseJson.hasOwnProperty("error")) {
                toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to delete chatbot!", type: "error"});
                return rejectWithValue(ResponseJson);
            }
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Chatbot deleted!", type: "success"});
            return {
                openai_assistant_id: openAIAssistantId,
            }
        } catch (error) {
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to delete chatbot!", type: "error"});
            return rejectWithValue({
                error: 'Fail to delete openAI assistant.',
                details: error.message
            });
        }
    }
);


export {
    getAllAccessibleOpenAIAssistants,
    createOpenAIAssistant,
    updateOpenAIAssistant,
    deleteOpenAIAssistant
};