import './styles/EditForm.css'
import selectStyles from "./styles/EditFormSelectStyles";
import Select from 'react-select'
import {useNavigate} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import CreatableSelect from "react-select/creatable";
import {useDispatch, useSelector} from "react-redux";

import {AsyncStatus} from "constants/asyncStatus";
import {selectSessionToken} from "features/session/sessionSelectors";
import {
    selectOpenAIChatModelsData,
    selectOpenAIChatModelsDataInitialized,
} from "features/entities/openAIChatModels/openAIChatModelsSelectors";
import {
    createOpenAIAssistant,
    updateOpenAIAssistant
} from "features/entities/openAIAssistants/openAIAssistantsThunks";
import {
    selectUserDataInitialized,
    selectUserId
} from "features/entities/user/userSelectors";
import {
    selectOpenAIAssistantsDataForUser,
    selectOpenAIAssistantsDataForUserInitialized,
    selectOpenAIAssistantsStatus
} from "features/entities/openAIAssistants/openAIAssistantsSelectors";
import {
    selectOpenAIVectorStoresData,
    selectOpenAIVectorStoresDataInitialized
} from "features/entities/openAIVectorStores/openAIVectorStoresSelectors";
import RoutesConfig from "configs/routesConfig";
import SecondaryButton from "components//core/SecondaryButton/SecondaryButton";
import PrimaryButton from "components//core/PrimaryButton/PrimaryButton";
import ExpandableTextarea from "./components/ExpandableTextarea/ExpandableTextarea";

const ChatbotEditForm = ({openAIAssistantId}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    // 储存表单被填入内容的state
    const [chatbotName, setChatbotName] = useState(null)
    const [chatbotDescription, setChatbotDescription] = useState(null)
    const [chatbotInstructions, setChatbotInstructions] = useState(null)
    const [chatbotSamplePrompts, setChatbotSamplePrompts] = useState(['', '', '', ''])
    const [chatbotTags, setChatbotTags] = useState(null)
    const [chatbotModel, setChatbotModel] = useState(null)
    const [chatbotKnowledgeBases, setChatbotKnowledgeBases] = useState(null)
    const [chatbotAccessLevel, setChatbotAccessLevel] = useState(null)

    const chatbotTagOptions = useMemo(() => [
        {value: 'CAES', label: 'CAES'},
        {value: 'CLEP', label: 'CLEP'},
        {value: 'Chinese', label: 'Chinese'},
        {value: 'English', label: 'English'},
    ], [])
    const chatbotAccessLevelOptions = useMemo(() => [
        {value: 'private', label: 'Myself'},
        {value: 'protected', label: 'My Students'},
        {value: 'public', label: 'Anyone'}
    ], [])
    const [chatbotModelOptions, setChatbotModelOptions] = useState([])
    const [chatbotKnowledgeBaseOptions, setChatbotKnowledgeBaseOptions] = useState([])

    const sessionToken = useSelector(selectSessionToken)
    const userDataId = useSelector(state => selectUserId(state))
    const userDataInitialized = useSelector(state => selectUserDataInitialized(state))

    const openAIAssistantsDataForUser = useSelector(state =>selectOpenAIAssistantsDataForUser(state, userDataId));
    const openAIAssistantsDataForUserInitialized = useSelector(state => selectOpenAIAssistantsDataForUserInitialized(state, userDataId));
    const openAIAssistantsStatus = useSelector(selectOpenAIAssistantsStatus)

    const openAIChatModelsData = useSelector(selectOpenAIChatModelsData)
    const openAIChatModelsDataInitialized = useSelector(state => selectOpenAIChatModelsDataInitialized(state))

    const openAIVectorStoresData = useSelector(state => selectOpenAIVectorStoresData(state))
    const openAIVectorStoresDataInitialized = useSelector(state => selectOpenAIVectorStoresDataInitialized(state))

    // 处理新建场景（没有openAIAssistantId）
    useEffect(() => {
        if (openAIAssistantId == null) {
            setChatbotName('')
            setChatbotDescription('')
            setChatbotInstructions('You are a helpful assistant.')
            setChatbotSamplePrompts(['', '', '', ''])
            setChatbotTags([])
            setChatbotModel(null)
            setChatbotKnowledgeBases([])
            setChatbotAccessLevel(null)
        }
    }, [openAIAssistantId])

    // 更新chatbot model信息(选项)
    useEffect(() => {
        if (! openAIChatModelsDataInitialized) return
        const chatbotModelOptions = []
        openAIChatModelsData.forEach(item => {
            if (item.status.is_active) {
                chatbotModelOptions.push({
                    value: item.id,
                    label: item.name,
                })
            }
        })
        setChatbotModelOptions(chatbotModelOptions)
    }, [openAIChatModelsDataInitialized, openAIChatModelsData])

    // 更新chatbot knowledge base信息(选项)
    useEffect(() => {
        if (! openAIVectorStoresDataInitialized) return
        const chatbotKnowledgeBaseOptions = openAIVectorStoresData.map(item => {
            return {
                value: item.id,
                label: item.name
            }
        })
        setChatbotKnowledgeBaseOptions(chatbotKnowledgeBaseOptions)
    }, [openAIVectorStoresDataInitialized, openAIVectorStoresData])

    // 更新表单预定义内容（输入框、多选框）
    useEffect(() => {
        if (! userDataInitialized) return
        if (! openAIAssistantsDataForUserInitialized) return
        const currentOpenAIAssistantData = openAIAssistantsDataForUser.find(item => item.id === openAIAssistantId)
        if (currentOpenAIAssistantData == null) return
        setChatbotName(currentOpenAIAssistantData.name)
        setChatbotDescription(currentOpenAIAssistantData.description)
        setChatbotInstructions(currentOpenAIAssistantData.instance.instructions)
        setChatbotSamplePrompts(currentOpenAIAssistantData.sample_prompts)
        setChatbotTags(currentOpenAIAssistantData.tags.map(tag => {
            return {
                value: tag,
                label: tag
            }
        }))
    }, [userDataInitialized, openAIAssistantsDataForUserInitialized, openAIAssistantId, openAIAssistantsDataForUser])

    // 更新表单预定义内容（下拉框 - Model）
    useEffect(() => {
        if (! userDataInitialized) return
        if (! openAIAssistantsDataForUserInitialized) return
        if (! chatbotModelOptions) return
        const currentOpenAIAssistantData = openAIAssistantsDataForUser.find(item => item.id === openAIAssistantId)
        if (currentOpenAIAssistantData == null) return
        const defaultChatModel = chatbotModelOptions.find(item => item.value === currentOpenAIAssistantData.openai_chat_model_id)
        if (! defaultChatModel) setChatbotModel(null)
        setChatbotModel(defaultChatModel)
    }, [userDataInitialized, openAIAssistantsDataForUserInitialized, openAIAssistantId, openAIAssistantsDataForUser, chatbotModelOptions])

    // 更新表单预定义内容（多选框 - KnowledgeBase）
    useEffect(() => {
        if (! userDataInitialized) return
        if (! openAIAssistantsDataForUserInitialized) return
        if (! chatbotKnowledgeBaseOptions.length) return
        const currentOpenAIAssistantData = openAIAssistantsDataForUser.find(item => item.id === openAIAssistantId)
        if (currentOpenAIAssistantData == null) return
        // const defaultKnowledgeBase = chatbotKnowledgeBaseOptions.find(item => item.value === currentOpenAIAssistantData.openai_vector_store_id)
        const defaultKnowledgeBase = chatbotKnowledgeBaseOptions.filter(item => currentOpenAIAssistantData.openai_vector_store_ids.includes(item.value))
        if (! defaultKnowledgeBase.length) setChatbotKnowledgeBases([])
        setChatbotKnowledgeBases(defaultKnowledgeBase)
    }, [userDataInitialized, openAIAssistantsDataForUserInitialized, openAIAssistantId, openAIAssistantsDataForUser, openAIVectorStoresData, openAIVectorStoresDataInitialized, chatbotKnowledgeBaseOptions])

    // 更新表单预定义内容（下拉框 - AccessLevel）
    useEffect(() => {
        if (! userDataInitialized) return
        if (! openAIAssistantsDataForUserInitialized) return
        if (! chatbotAccessLevelOptions) return
        const currentOpenAIAssistantData = openAIAssistantsDataForUser.find(item => item.id === openAIAssistantId)
        if (currentOpenAIAssistantData == null) return
        const defaultAccessLevel = chatbotAccessLevelOptions.find(item => item.value === currentOpenAIAssistantData.access_level)
        if (! defaultAccessLevel) setChatbotAccessLevel(null)
        setChatbotAccessLevel(defaultAccessLevel)
    }, [userDataInitialized, openAIAssistantsDataForUserInitialized, openAIAssistantId, openAIAssistantsDataForUser, chatbotAccessLevelOptions])

    // 提交表单
    const handleSubmit = (e) => {
        e.preventDefault();
        if (openAIAssistantsStatus === AsyncStatus.PENDING) return
        const data = {
            token: sessionToken,
            name: chatbotName,
            description: chatbotDescription,
            instructions: chatbotInstructions,
            samplePrompts: chatbotSamplePrompts,
            tags: chatbotTags.map(tag => tag.value),
            accessLevel: chatbotAccessLevel.value,
            openAIChatModelId: chatbotModel.value,
            openAIVectorStoreIds: chatbotKnowledgeBases.map(knowledgeBase => knowledgeBase.value)
        };
        if (openAIAssistantId == null) {
            dispatch(createOpenAIAssistant(data))
        } else {
            dispatch(updateOpenAIAssistant({
                openAIAssistantId: openAIAssistantId,
                ...data
            }))
        }
        navigate(RoutesConfig.app.CHATBOT_STUDIO)
    }

    return (
        <form className={'chatbot edit-form'} onSubmit={handleSubmit}>

            <label className={"required"}>
                Name of Chatbot
            </label>
            <input
                type={"text"}
                maxLength={256}
                value={chatbotName || ''}
                onChange={(e) => {
                    console.log('changing chatbot name')
                    setChatbotName(e.target.value)
                }}
                required
            />

            <label className={"required"}>
                Description
            </label>
            <ExpandableTextarea
                maxLength={256000}
                value={chatbotDescription || ''}
                onChange={(e) => setChatbotDescription(e.target.value)}
                required
            />

            <label className={"required"}>
                Instruction
            </label>
            <ExpandableTextarea
                maxLength={256000}
                value={chatbotInstructions || ''}
                onChange={(e) => setChatbotInstructions(e.target.value)}
                required
            />

            <label>
                Tags
            </label>
            <CreatableSelect
                isMulti
                isClearable
                value={chatbotTags}
                onChange={(selectedOptions) => {
                    setChatbotTags(selectedOptions)
                }}
                options={chatbotTagOptions || []}
                styles={selectStyles}
            />

            {
                Array.from({length: chatbotSamplePrompts.length}).map((_, index) => {
                    return (
                        <React.Fragment key={index}>
                            <label>
                                {`Sample Query ${index + 1}`}
                            </label>
                            <input
                                value={chatbotSamplePrompts[index]}
                                onChange={(e) => {
                                    const newSamplePrompts = chatbotSamplePrompts.slice()
                                    newSamplePrompts[index] = e.target.value
                                    setChatbotSamplePrompts(newSamplePrompts)
                                }}
                                maxLength={512}
                            />
                        </React.Fragment>
                    )
                })
            }

            <div className={'module'}>
                <div className={'control'}>
                    {
                        chatbotSamplePrompts.length > 4 &&
                        <SecondaryButton
                            type={'button'}
                            onClick={() => {
                                setChatbotSamplePrompts(chatbotSamplePrompts.slice(0, -1))
                            }}
                        >
                            -
                        </SecondaryButton>
                    }
                    {
                        chatbotSamplePrompts.length < 10 &&
                        <SecondaryButton
                            type={'button'}
                            onClick={() => {
                                setChatbotSamplePrompts([...chatbotSamplePrompts, ''])
                            }}
                        >
                            +
                        </SecondaryButton>
                    }
                </div>
            </div>

            <label
                className={"required"}
            >
                Chat Model
            </label>
            <Select
                value={chatbotModel}
                onChange={(selectedOption) => {
                    setChatbotModel(selectedOption)
                }}
                options={chatbotModelOptions}
                isOptionDisabled={(option) => option.disabled}
                styles={selectStyles}
                required={true}
            />

            <label>
                Corpus
            </label>
            <CreatableSelect
                isMulti
                isClearable
                value={chatbotKnowledgeBases}
                onChange={(selectedOptions) => {
                    setChatbotKnowledgeBases(selectedOptions)
                }}
                options={chatbotKnowledgeBaseOptions || []}
                styles={selectStyles}
            />
            {/* <Select
                value={chatbotKnowledgeBases}
                onChange={(selectedOption) => {
                    setChatbotKnowledgeBases(selectedOption)
                }}
                options={chatbotKnowledgeBaseOptions}
                styles={selectStyles}
                isClearable={true}
                required={false}
            /> */}

            <label className={"required"}>
                Access Level
            </label>
            <Select
                options={chatbotAccessLevelOptions}
                value={chatbotAccessLevel}
                onChange={(selectedOption) => {
                    setChatbotAccessLevel(selectedOption)
                }}
                styles={selectStyles}
                required={true}
            />

            <div className={'button-group'}>
                <SecondaryButton type={'button'}
                    onClick={(event) => {
                        event.preventDefault();
                        navigate(RoutesConfig.app.CHATBOT_STUDIO)
                    }}
                >
                    Cancel
                </SecondaryButton>

                <PrimaryButton type={"submit"}>
                    Save
                </PrimaryButton>
            </div>

        </form>
    )
}

export default ChatbotEditForm;